import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"

export function useMonitoringPresetBreadcrumbs({
  editName,
}: { editName?: string } = {}) {
  const { t, langKeys } = useTranslate()

  const base = [
    {
      to: Routes.Settings.location(),
      label: t(langKeys.settings),
    },
    {
      to: Routes.SettingsPresets.location(),
      label: t(langKeys.preset_monitoring_presets),
    },
  ]

  const noise = [
    ...base,
    {
      to: Routes.SettingsPresetsNoise.location(),
      label: t(langKeys.presets_noise_monitoring_title),
    },
  ]

  const noiseEdit = [...noise, { to: { pathname: "#" }, label: editName ?? "" }]

  return { base, noise, noiseEdit }
}
